var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal fade",attrs:{"id":"modal-form-crear-tepTurno","tabindex":"-1","role":"dialog","aria-hidden":"true","data-backdrop":"static","data-keyboard":"false"}},[_c('div',{staticClass:"modal-dialog modal-md"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v("Validar Turno")]),_c('button',{ref:"closeModal",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"vehiculo"}},[_vm._v("Vehiculo")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                    _vm.$v.form.vehiculo_id.$invalid ? 'is-invalid' : 'is-valid',
                    _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                  ],attrs:{"placeholder":"Placa","label":"placa","options":_vm.listasForms.vehiculos,"filterable":false},on:{"search":_vm.buscarVehiculos,"input":function($event){return _vm.selectVehiculo()}},model:{value:(_vm.vehiculo),callback:function ($$v) {_vm.vehiculo=$$v},expression:"vehiculo"}})],1)])])]),_c('div',{staticClass:"modal-footer"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[(_vm.$store.getters.can('tep.turnos.create'))?_c('button',{directives:[{name:"show",rawName:"v-show",value:(
                  !_vm.$v.form.vehiculo_id.$invalid &&
                    !_vm.$v.form.fecha_inicial.$invalid &&
                    !_vm.$v.form.hora_inicial.$invalid
                ),expression:"\n                  !$v.form.vehiculo_id.$invalid &&\n                    !$v.form.fecha_inicial.$invalid &&\n                    !$v.form.hora_inicial.$invalid\n                "}],staticClass:"btn btn-success",attrs:{"type":"button"},on:{"click":function($event){return _vm.validarProgrmacion()}}},[_c('i',{staticClass:"fas fa-check-double col-md-12"}),_c('strong',[_vm._v(" Validar")])]):_vm._e()])])]),(_vm.programaciones.length > 0)?_c('div',{staticClass:"modal-footer"},[_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm",attrs:{"id":"users"}},[_vm._m(0),_c('tbody',_vm._l((_vm.programaciones),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(" "+_vm._s(item.id)+" ")]),_c('td',[_vm._v(_vm._s(item.fecha))]),_c('td',[_vm._v(_vm._s(item.vehiculo.placa))]),_c('td',[_vm._v(" "+_vm._s(item.conductor ? item.conductor.nombres + " " + item.conductor.apellidos : "")+" ")]),_c('td',[_c('a',{staticClass:"dropdown-item",staticStyle:{"cursor":"pointer"},attrs:{"id":"botonImp","type":"button"},on:{"click":function($event){return _vm.selectprogramacion(item)}}},[_c('i',{staticClass:"fas fa-flag-checkered"}),_vm._v(" Crear turno ")])]),_c('td')])}),0)])]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Postulacion")]),_c('th',[_vm._v("Fecha")]),_c('th',[_vm._v("Placa")]),_c('th',[_vm._v("Conductor")]),_c('th',[_vm._v("Seleccione...")])])])
}]

export { render, staticRenderFns }